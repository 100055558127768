import React, { useContext, useState } from 'react';

const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
  const [navCollapsed, setNavCollapsed] = useState(false);

  function toggleCollapseNav() {
    setNavCollapsed(!navCollapsed);
  }

  return <SidebarContext.Provider value={{ navCollapsed, toggleCollapseNav }}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => useContext(SidebarContext);
