const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

const defaultOptions = {
  baseURL: AUTH_API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic ' + window.btoa(CLIENT_ID + ':' + CLIENT_SECRET),
  },
};

const interceptorRequest = (config) => config;
const interceptorRequestError = (error) => Promise.reject(error);
const interceptorResponse = (response) => response;

const interceptorResponseError = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('authData');
    window.location.href = '/signin';
  }

  return Promise.reject(error);
};

export { interceptorRequest, interceptorRequestError, interceptorResponse, interceptorResponseError };

export default defaultOptions;
