import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';

const App = () => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path="/dashboard" component={asyncComponent(() => import('./Dashboard'))} />
      <Route path="/contact-page" component={asyncComponent(() => import('./ContactPage.js'))} />
      <Route path="/profile" component={asyncComponent(() => import('./Settings/Profile'))} />
      <Route path="/content-page" component={asyncComponent(() => import('./ContentPage.js'))} />
      <Route path="/customization" component={asyncComponent(() => import('./Settings/Branding'))} />
      <Route path="/how-to" component={asyncComponent(() => import('./HowTo'))} />
      <Route path="/customer-interactions/photo-approval" component={asyncComponent(() => import('./Communication/PhotosApproval'))} />
    </Switch>
  </div>
);

export default App;
