import { createSlice } from '@reduxjs/toolkit';
import { getPhotosApproval, setPhotosApproval } from './PhotosApprovalActions';

const PhotosApprovalSlice = createSlice({
  name: 'photosApproval',
  initialState: {},
  reducers: {
    removeApprovedPhoto(state, action) {
      state.photos = state.photos.filter((val) => val.resourceId !== action.payload);
    },
    setLoadingState(state, action) {
      const newPhotos = state.photos.map(item => {
        if (item.resourceId === action.payload) {
          return { ...item, isLoading: true }
        }
        return item
      })
      state.photos = newPhotos;
    }
  },
  extraReducers: {
    [getPhotosApproval.pending]: (state) => {
      state.isLoading = true;
    },
    [getPhotosApproval.fulfilled]: (state, action) => {
      state.isLoading = false;
      const newPhotos = action.payload.map(item => ({ ...item, isLoading: false }))
      state.photos = newPhotos;
    },
    [getPhotosApproval.rejected]: (state) => {
      state.isLoading = false;
    },
    [setPhotosApproval.fulfilled]: (state, action) => {
      const newPhotos = state.photos.map(item => {
        if (item.resourceId === action.payload) {
          return { ...item, status: 'success' }
        }
        return item
      })
      state.photos = newPhotos;
    },
    [setPhotosApproval.rejected]: (state, action) => {
      const newPhotos = state.photos.map(item => {
        if (item.resourceId === action.payload) {
          return { ...item, status: 'error' }
        }
        return item
      })
      state.photos = newPhotos;

    },
  },
});

export const PhotosApprovalActions = PhotosApprovalSlice.actions;
export default PhotosApprovalSlice;
