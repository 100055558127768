import { request } from './request';

const BASE_URL = process.env.REACT_APP_AUTH_API_URL;

const loginRequest = (username, password) => {
  const body = new URLSearchParams();
  body.append('username', username);
  body.append('password', password);
  body.append('scope', 'profile permission offline_access');
  body.append('grant_type', 'password');

  return request.post(`${BASE_URL}/connect/token`, body);
};

const refreshRequest = (refreshToken) => {
  const body = new URLSearchParams();
  body.append('grant_type', 'refresh_token');
  body.append('refresh_token', refreshToken);

  return request.post(`${BASE_URL}/connect/token`, body);
};

export const authService = {
  loginRequest,
  refreshRequest,
};
