import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import { useSelector } from 'react-redux';
import { Links } from '../../constants/Links';
import { isAllowed } from '../../util/permisionsService';

const SubMenu = Menu.SubMenu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector((state) => state.settings);
  const { permissionIds } = useSelector(({ auth }) => auth);
  const location = useLocation();

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[location.pathname]}
            selectedKeys={[location.pathname]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {Links.map((link) =>
              isAllowed(link.permissionId, permissionIds) ? (
                link.childs ? (
                  <SubMenu
                    key={`submenu-${link.path}`}
                    title={
                      <span>
                        <i className={link.iconClasses} />
                        <span>{link.text}</span>
                      </span>
                    }
                  >
                    {link.childs.map((subMenu) => (
                      <Menu.Item key={subMenu.path}>
                        <Link to={subMenu.path}>
                          <i className={subMenu.iconClasses} />
                          <span>{subMenu.text}</span>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={link.path}>
                    {link.external ? (
                      <a href={link.path} target="_blank" rel="noopener noreferrer">
                        {link.icon ? link.icon : <i className={link.iconClasses} />}
                        <span className="gx-m-0">{link.text}</span>
                      </a>
                    ) : (
                      <Link to={link.path}>
                        {link.icon ? link.icon : <i className={link.iconClasses} />}
                        <span className="gx-m-0">{link.text}</span>
                      </Link>
                    )}
                  </Menu.Item>
                )
              ) : (
                <></>
              )
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
