import { authService } from '../authenticationService/authService';
import jwt_decode from 'jwt-decode';
import { notification } from 'antd';
import { createIntl, createIntlCache } from 'react-intl';
import locale_en from '../lngProvider/locales/en_US.json';
import locale_es from '../lngProvider/locales/es_ES.json';
import locale_zh from '../lngProvider/locales/zh-Hans.json';

const data = {
  en: locale_en,
  es: locale_es,
  zh: locale_zh,
};

const headers = {
  'Content-Type': 'application/json',
};

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers,
};

const interceptorRequest = (config) => {
  const { token } = JSON.parse(localStorage.getItem('authData'));
  const appId = localStorage.getItem('selectedApp') ? JSON.parse(localStorage.getItem('selectedApp')).id : null;
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  if (appId) {
    config.headers['appId'] = appId;
  }
  return config;
};

const interceptorRequestError = (error) => Promise.reject(error);
const interceptorResponse = (response) => response;

const interceptorResponseError = async (error) => {
  if (error.response.status === 401) {
    try {
      const oldAuthData = JSON.parse(localStorage.getItem('authData'));
      const newAuthData = await authService.refreshRequest(oldAuthData.refreshToken);
      const decodedJwt = jwt_decode(newAuthData.data.access_token);
      const authData = {
        token: newAuthData.data.access_token,
        name: decodedJwt.name,
        refreshToken: newAuthData.data.refresh_token,
        expiresToken: newAuthData.data.expires_in,
      };
      localStorage.setItem('authData', JSON.stringify(authData));
    } catch (error) {
      localStorage.removeItem('authData');
      window.location.href = '/signin';

      return Promise.reject(error);
    }
  }
  const language = JSON.parse(localStorage.getItem('locale')).locale || 'en';
  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: language,
      messages: data[language],
    },
    cache
  );

  const smthWentWrong = intl.formatMessage({
    id: 'error.somethingWentWrong',
    defaultMessage: 'Something went wrong, try again later',
  });

  notification['error']({
    message: 'Oops',
    description: smthWentWrong,
    duration: 3,
  });

  return Promise.reject(error);
};

export { interceptorRequest, interceptorRequestError, interceptorResponse, interceptorResponseError };

export default defaultOptions;
