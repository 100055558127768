import axios from 'axios';

const request = axios.create({ headers: { 'Content-Type': 'application/json' } });

const getShortIoLink = (link) => {
  return request.get(`https://api.short.io/links/by-original-url?domain=linkto.guide&originalURL=${link}`, {
    headers: {
      Accept: 'application/json',
      Authorization: process.env.REACT_APP_SHORT_IO_SECRET,
    },
  });
};

const createShortIoLink = (link) => {
  return request.post(`https://api.short.io/links`, {
    headers: {
      Authorization: process.env.REACT_APP_SHORT_IO_SECRET,
    },
    body: {
      domain: 'linkto.guide',
      originalURL: link,
    },
  });
};

const updateShortIoLink = (linkId, newLink) => {
  return request.post(`https://api.short.io/links/${linkId}`, {
    headers: {
      Authorization: process.env.REACT_APP_SHORT_IO_SECRET,
    },
    body: {
      domain: 'linkto.guide',
      allowDuplicates: false,
      originalURL: newLink,
    },
  });
};

export const qrCodeService = {
  getShortIoLink,
  createShortIoLink,
  updateShortIoLink,
};
