import axios from 'axios';
import defaultOptions from './Interceptors';
import { interceptorRequest, interceptorRequestError, interceptorResponse, interceptorResponseError } from './Interceptors';

const instance = axios.create(defaultOptions);

instance.interceptors.request.use(interceptorRequest, interceptorRequestError);

instance.interceptors.response.use(interceptorResponse, interceptorResponseError);

const post = (url, body) => instance.post(url, body);

export const request = {
  post,
};
