import { request } from './Request';

const API_URL = process.env.REACT_APP_API_URL;

const getTotals = (from, to) => {
  const params = { from, to };
  return request.get(`${API_URL}/api/dashboard/v2/extendeddata`, { params });
};

const getTopViewedGuide = (fromDate, toDate) => {
  return request.get(`${API_URL}/api/lessonstatistics/mostviewed/${fromDate}/${toDate}`);
};

const getDailyViews = (from, to) => {
  return request.get(`${API_URL}/api/dashboard/dataByRange?from=${from}&to=${to}`);
};

const getCompletedGoals = (from, to) => {
  return request.get(`${API_URL}/api/lessonstatistics/completedgoals/${from}/${to}`);
};

export const dashboardService = {
  getTotals,
  getTopViewedGuide,
  getDailyViews,
  getCompletedGoals,
};
