import { createAsyncThunk } from '@reduxjs/toolkit';
import { appsService } from '../../service/AppsService';
import { qrCodeService } from '../../service/QrCodeService';

export const getAppsAction = createAsyncThunk('apps/getAppsAction', async () => {
  const response = await appsService.appsRequest();
  localStorage.setItem('allApps', JSON.stringify(response.data));
  return response.data;
});

export const getProjectsAction = createAsyncThunk('apps/getNavItemsAction', async (appId) => {
  const response = await appsService.getProjects(appId);
  return response.data;
});

export const getProjectShortIoLink = createAsyncThunk('apps/getShortIoLink', async (link) => {
  const response = await qrCodeService.getShortIoLink(link);
  return response.data;
});

export const createProjectShortIoLink = createAsyncThunk('apps/createShortIoLink', async (link) => {
  const response = await qrCodeService.createShortIoLink(link);
  return response.data;
});

export const updateProjectShortIoLink = createAsyncThunk('apps/updateShortIoLink', async ({ linkId, newLink }) => {
  const response = await qrCodeService.updateShortIoLink(linkId, newLink);
  return response.data;
});
