import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import IntlMessages from '../util/IntlMessages';

export const Links = [
  {
    path: '/settings',
    iconClasses: 'icon icon-setting',
    text: <IntlMessages id="sidebar.settings" />,
    permissionId: 101,
    childs: [
      {
        path: '/profile',
        iconClasses: 'icon icon-profile',
        text: <IntlMessages id="sidebar.content.profile" />,
        permissionId: 101,
      },
      {
        path: '/customization',
        iconClasses: 'icon icon-ckeditor',
        text: <IntlMessages id="sidebar.content.customization" />,
        permissionId: 101,
      },
    ],
  },
  {
    path: '/dashboard',
    iconClasses: 'icon icon-chart-line',
    text: <IntlMessages id="sidebar.dashboard" />,
    permissionId: 101,
  },
  {
    path: '/content-page',
    iconClasses: 'icon icon-card',
    text: <IntlMessages id="sidebar.content.overview" />,
    permissionId: 101,
  },
  {
    path: '/customer-interactions',
    iconClasses: 'icon icon-keyboard',
    text: <IntlMessages id="sidebar.customer.interactions" />,
    permissionId: 101,
    childs: [
      {
        path: '/customer-interactions/photo-approval',
        iconClasses: 'icon icon-image',
        text: <IntlMessages id="sidebar.customer.interactions.photo" />,
        permissionId: 101,
      },
    ],
  },
  {
    path: 'http://backoffice.dev.freetimeacademy.com/#/cms/application',
    external: true,
    iconClasses: 'icon icon-apps',
    text: 'Makeree 2.0',
    permissionId: 101,
  },
  {
    path: '/how-to',
    icon: <QuestionCircleOutlined className="icon" />,
    text: <IntlMessages id="sidebar.how.to" />,
    permissionId: 101,
  },
  {
    path: 'https://shop.makeree.com/product/starter-plan/',
    external: true,
    iconClasses: 'icon icon-pricing-table',
    text: <IntlMessages id="sidebar.payments" />,
    permissionId: 101,
  },
  {
    path: '/contact-page',
    iconClasses: 'icon icon-email',
    text: <IntlMessages id="sidebar.contact.makeree" />,
    permissionId: 101,
  },
];
