import { createSlice } from '@reduxjs/toolkit';
import { getBrandingSettings, setBrandingSettings } from './BrandingActions';

const BrandingSlice = createSlice({
  name: 'branding',
  initialState: {
    isLoading: false,
    getBrandingSettings: [],
  },
  reducers: {
    removeSettingStatus(state) {
      state.setSettingsStatus = null;
    },
  },
  extraReducers: {
    [getBrandingSettings.pending]: (state) => {
      state.isLoading = true;
    },
    [getBrandingSettings.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.brandingSettings = action.payload;
    },
    [getBrandingSettings.rejected]: (state) => {
      state.isLoading = false;
    },
    [setBrandingSettings.pending]: (state) => {
      state.setSettingsStatus = 'loading';
    },
    [setBrandingSettings.fulfilled]: (state) => {
      state.setSettingsStatus = 'success';
    },
    [setBrandingSettings.rejected]: (state) => {
      state.onSetSettingsError = 'true';
      state.setSettingsStatus = 'error';
    },
  },
});

export const brandingActions = BrandingSlice.actions;
export default BrandingSlice;
