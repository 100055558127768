import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import App from './containers/App/index';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';

const NextApp = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default NextApp;
