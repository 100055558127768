import React, { useEffect } from 'react';
import { Layout, Popover, Select } from 'antd';
import { Link } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import languageData from './languageData';
import UserInfo from '../../components/UserInfo';
import Auxiliary from 'util/Auxiliary';
import { SettingsActions } from '../../store/Settings/SettingsSlice';
import { useIntl } from 'react-intl';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../constants/ThemeSetting';
import { useDispatch, useSelector } from 'react-redux';
import { getAppsAction } from '../../store/Apps/AppsActions';
import { AppsActions } from '../../store/Apps/AppsSlice';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useSidebar } from '../../context/SidebarContext';

const { Header } = Layout;
const Topbar = () => {
  const { locale, navStyle } = useSelector((state) => state.settings);
  const { apps } = useSelector((state) => state.apps);
  const dispatch = useDispatch();
  const { Option } = Select;
  const intl = useIntl();
  const { toggleCollapseNav } = useSidebar();

  const width = useWindowWidth();

  useEffect(() => {
    dispatch(getAppsAction());
  }, [dispatch]);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll " id="lang-popover-height">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={() => dispatch(SettingsActions.switchLanguage(language))}>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const handleChange = (value) => {
    if (value === 'All apps') {
      dispatch(AppsActions.removeSelectedApp());
    } else {
      dispatch(AppsActions.setSelectedApp(JSON.parse(value)));
      localStorage.setItem('selectedApp', value);
    }
  };

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              toggleCollapseNav();
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img src={'https://www.makeree.com/wp-content/uploads/2020/05/makeree-logo-dark.png?88e6a0&88e6a0'} alt="logo" style={{ width: '50px' }} />
      </Link>
      <Select
        defaultValue={localStorage.getItem('selectedApp') ? localStorage.getItem('selectedApp') : 'All apps'}
        onChange={handleChange}
        style={{ width: 150 }}
        showSearch
      >
        <Option value="All apps">{intl.formatMessage({ id: 'all.apps' })}</Option>
        {apps.map((app) => (
          <Option key={app.id} value={JSON.stringify(app)}>
            {app.name}
          </Option>
        ))}
      </Select>
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()} trigger="click">
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className="gx-pl-2 gx-language-name">{locale.name}</span>
              <i className="icon icon-chevron-down gx-pl-2" />
            </span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  );
};

export default Topbar;
