import { createAsyncThunk } from '@reduxjs/toolkit';
import { settingsService } from '../../service/SettingsService';

export const getBrandingSettings = createAsyncThunk('get/brandingSettings', async (id, { rejectWithValue }) => {
  try {
    const result = await settingsService.getTheme(id);
    return result.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setBrandingSettings = createAsyncThunk('set/brandingSettings', async ({ id, product }, { rejectWithValue }) => {
  try {
    const result = await settingsService.setTheme(id, product);
    console.log(result.data);
  } catch (err) {
    return rejectWithValue(err);
  }
});
