import { createAsyncThunk } from '@reduxjs/toolkit';
import { photosApprovalService } from '../../service/PhotosApprovalService';

export const getPhotosApproval = createAsyncThunk('get/photosApproval', async () => {
  const result = await photosApprovalService.getApprovals();
  return result.data;
});

export const setPhotosApproval = createAsyncThunk('set/photosApproval', async ({ projectId, resourceId, approved }, { rejectWithValue }) => {
  try {
    await photosApprovalService.approvePhoto(projectId, resourceId, approved);
    return resourceId;
  } catch (err) {
    return rejectWithValue(err);
  }
});
