import { createSlice } from '@reduxjs/toolkit';
import { getTotalsAction, getTopViewedGuideAction, getDailyViewsAction, getCompletedGoals } from './DashboardActions';

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    totals: {},
    topGuide: {},
    completedGoals: {},
    isLoadingTotals: false,
    isLoadingTopGuide: false,
    isLoadingCompletedGoals: false,
  },
  reducers: {},
  extraReducers: {
    [getTotalsAction.pending]: (state) => {
      state.isLoadingTotals = true;
    },
    [getTotalsAction.fulfilled]: (state, action) => {
      state.isLoadingTotals = false;
      state.totals = action.payload;
    },
    [getTotalsAction.rejected]: (state) => {
      state.isLoadingTotals = false;
      state.totals = {};
    },

    [getTopViewedGuideAction.pending]: (state) => {
      state.isLoadingTopGuide = true;
    },
    [getTopViewedGuideAction.fulfilled]: (state, action) => {
      state.isLoadingTopGuide = false;
      state.topGuide = action.payload;
    },
    [getTopViewedGuideAction.rejected]: (state) => {
      state.isLoadingTopGuide = false;
    },

    [getDailyViewsAction.pending]: (state) => {
      state.isLoadingDailyViews = true;
      state.error = false;
    },
    [getDailyViewsAction.fulfilled]: (state, action) => {
      state.isLoadingDailyViews = false;
      state.error = false;
      state.dailyViews = action.payload;
    },
    [getDailyViewsAction.rejected]: (state) => {
      state.isLoadingDailyViews = false;
      state.dailyViews = [];
      state.error = true;
    },

    [getCompletedGoals.pending]: (state) => {
      state.isLoadingCompletedGoals = true;
    },
    [getCompletedGoals.fulfilled]: (state, action) => {
      state.isLoadingCompletedGoals = false;
      state.completedGoals = action.payload;
    },
    [getCompletedGoals.rejected]: (state) => {
      state.isLoadingCompletedGoals = false;
    },
  },
});

export const DashboardActions = DashboardSlice.actions;
export default DashboardSlice;
