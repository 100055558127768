import { createSlice } from '@reduxjs/toolkit';
import { LAYOUT_TYPE_FULL, NAV_STYLE_FIXED, THEME_TYPE_SEMI_DARK } from '../../constants/ThemeSetting';

const SettingsSlice = createSlice({
  name: 'settings',
  initialState: {
    navStyle: NAV_STYLE_FIXED,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_SEMI_DARK,
    themeColor: '',
    isDirectionRTL: false,
    locale: JSON.parse(localStorage.getItem('locale')) || {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us',
    },
  },
  reducers: {
    setThemeType(state, action) {
      state.themeType = action.payload;
    },
    setThemeColor(state, action) {
      state.themeColor = action.payload;
    },
    setDirectionRTL(state, action) {
      state.isDirectionRTL = action.payload;
    },
    onNavStyleChange(state, action) {
      state.navStyle = action.payload;
    },
    onLayoutTypeChange(state, action) {
      state.layoutType = action.payload;
    },
    switchLanguage(state, action) {
      state.locale = action.payload;
      localStorage.setItem('locale', JSON.stringify(action.payload));
    },
  },
});

export const SettingsActions = SettingsSlice.actions;
export default SettingsSlice;
