import { createSlice } from '@reduxjs/toolkit';
import { createProjectShortIoLink, getAppsAction, getProjectsAction, getProjectShortIoLink, updateProjectShortIoLink } from './AppsActions';

const AppsSlice = createSlice({
  name: 'apps',
  initialState: {
    apps: JSON.parse(localStorage.getItem('allApps')) || [],
    selectedApp: JSON.parse(localStorage.getItem('selectedApp')) || null,
    existsShortIoLink: false,
  },
  reducers: {
    setSelectedApp(state, action) {
      state.selectedApp = action.payload;
    },
    removeSelectedApp(state) {
      state.selectedApp = null;
      localStorage.removeItem('selectedApp');
    },
    removeGuides(state) {
      state.navItems = null;
    },
  },
  extraReducers: {
    [getAppsAction.pending]: (state) => {
      state.loader = true;
    },
    [getAppsAction.fulfilled]: (state, action) => {
      state.loader = false;
      state.apps = action.payload;
    },
    [getAppsAction.rejected]: (state) => {
      state.loader = false;
    },

    [getProjectsAction.pending]: (state) => {
      state.loaderProjects = true;
    },
    [getProjectsAction.fulfilled]: (state, action) => {
      state.loaderProjects = false;
      state.projects = action.payload;
    },
    [getProjectsAction.rejected]: (state) => {
      state.loaderProjects = false;
    },

    [getProjectShortIoLink.pending]: (state) => {
      state.qrLoader = true;
      state.existsShortIoLink = false;
    },
    [getProjectShortIoLink.fulfilled]: (state, action) => {
      state.qrLoader = false;
      state.shortIoLink = action.payload.shortURL;
      state.linkId = action.payload.id;
      state.existsShortIoLink = true;
    },
    [getProjectShortIoLink.rejected]: (state) => {
      state.qrLoader = false;
      state.existsShortIoLink = false;
    },

    [createProjectShortIoLink.pending]: (state) => {
      state.qrLoader = true;
    },
    [createProjectShortIoLink.fulfilled]: (state, action) => {
      state.qrLoader = false;
      state.shortIoLink = action.payload.shortURL;
    },
    [createProjectShortIoLink.rejected]: (state) => {
      state.qrLoader = false;
    },

    [updateProjectShortIoLink.pending]: (state) => {
      state.qrLoader = true;
    },
    [updateProjectShortIoLink.fulfilled]: (state, action) => {
      state.qrLoader = false;
      state.shortIoLink = action.payload.shortURL;
      state.linkId = action.payload.id;
    },
    [updateProjectShortIoLink.rejected]: (state) => {
      state.qrLoader = false;
    },
  },
});

export const AppsActions = AppsSlice.actions;
export default AppsSlice;
