import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '../../authenticationService/authService';
import jwt_decode from 'jwt-decode';

export const authAction = createAsyncThunk('post/userSignIn', async (user, { rejectWithValue }) => {
  try {
    const { username, password } = user;
    const result = await authService.loginRequest(username, password);
    const decodedJwt = jwt_decode(result.data.access_token);
    const authData = {
      token: result.data.access_token,
      name: decodedJwt.name,
      refreshToken: result.data.refresh_token,
      expiresToken: result.data.expires_in,
    };
    localStorage.setItem('authData', JSON.stringify(authData));

    return { token: result.data.access_token, decodedJwt: decodedJwt };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const refreshAuthAction = createAsyncThunk('post/refreshToken', async (refreshToken, { rejectWithValue }) => {
  try {
    const result = await authService.refreshRequest(refreshToken);
    const decodedJwt = jwt_decode(result.data.access_token);
    const authData = {
      token: result.data.access_token,
      name: decodedJwt.name,
      refreshToken: result.data.refresh_token,
      expiresToken: result.data.expires_in,
    };
    localStorage.setItem('authData', JSON.stringify(authData));

    return { token: result.data.access_token, decodedJwt: decodedJwt };
  } catch (err) {
    return rejectWithValue(err);
  }
});
