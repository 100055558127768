import axios from 'axios';
import defaultOptions from './Interceptors';
import { interceptorRequest, interceptorRequestError, interceptorResponse, interceptorResponseError } from './Interceptors';

const instance = axios.create(defaultOptions);

instance.interceptors.request.use(interceptorRequest, interceptorRequestError);

instance.interceptors.response.use(interceptorResponse, interceptorResponseError);

const get = (url, config) => instance.get(url, config);

const post = (url, body) => instance.post(url, body);

const _delete = (url) => instance.delete(url);

export const request = {
  get,
  post,
  delete: _delete,
};
