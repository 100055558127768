import React, { useEffect } from 'react';
import { Button, Divider, Form, Input, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '../../components/CircularProgress';
import { AuthActions } from '../../store/Auth/AuthSlice';
import { authAction } from '../../store/Auth/authActions';
import logo from '../../assets/img/Makeree_White.png';

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, authUser, showMessage, alertMessage } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(AuthActions.hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/dashboard');
    }
  });

  const onFinishFailed = () => {};

  const onFinish = (values) => {
    console.log(values);
    dispatch(authAction(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <img src={logo} alt="logo" className="logo" />
            <Divider className="ant-btn-primary " />
            <p className="logo-text">
              <IntlMessages id="sign.in.text" />
            </p>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item rules={[{ required: true, message: 'The input is not valid Username!' }]} name="username">
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                <Input.Password type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Link className="gx-login-form-forgot" to="/forgot-password">
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
