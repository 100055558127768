import { request } from './Request';

const API_URL = process.env.REACT_APP_API_URL;

const getApprovals = () => {
  return request.get(`${API_URL}/api/userprojectapproval/list/0`);
};

const approvePhoto = (projectId, resourceId, approved) => {
  return request.post(`${API_URL}/api/userprojectapproval/${projectId}/${resourceId}/${approved}/${approved}`);
};

export const photosApprovalService = {
  getApprovals,
  approvePhoto,
};
