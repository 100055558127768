import { createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardService } from '../../service/DashboardService';

export const getTotalsAction = createAsyncThunk('get/dashboardTotals', async ({ from, to }, { rejectWithValue }) => {
  try {
    const result = await dashboardService.getTotals(from, to);
    return result.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getTopViewedGuideAction = createAsyncThunk('get/topViewedGuide', async ({ fromDate, toDate }, { rejectWithValue }) => {
  try {
    const result = await dashboardService.getTopViewedGuide(fromDate, toDate);
    const topGuide = result.data;
    return topGuide;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDailyViewsAction = createAsyncThunk('get/dailyViews', async ({ from, to }, { rejectWithValue }) => {
  try {
    const result = await dashboardService.getDailyViews(from, to);
    return result.data.dailyStatisticsData;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompletedGoals = createAsyncThunk('get/completedGoals', async ({ from, to }, { rejectWithValue }) => {
  try {
    const result = await dashboardService.getCompletedGoals(from, to);
    return result.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
