import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appLogo from '../../assets/img/appLogo.svg.png';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE, THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import useWindowWidth from '../../hooks/useWindowWidth';

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  let { navStyle, themeType } = useSelector((state) => state.settings);

  const width = useWindowWidth();

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  return (
    <div className="gx-layout-sider-header">
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} ${
              themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''
            }`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          />
        </div>
      ) : null}

      <Link to="/dashboard" className="gx-site-logo">
        <img src={appLogo} alt="logo" className="sidebar-app-logo" />
      </Link>
    </div>
  );
};

export default SidebarLogo;
