import { request } from './Request';

const appsRequest = () => {
  return request.get(`${process.env.REACT_APP_API_URL}/api/application/list`);
};

const getProjects = () => {
  return request.get(`${process.env.REACT_APP_API_URL}/api/content/course/allcoursesExtended`);
};

export const appsService = {
  appsRequest,
  getProjects,
};
