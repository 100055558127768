import React from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { AuthActions } from '../../store/Auth/AuthSlice';
import { useSelector } from 'react-redux';
import userLogo from '../../assets/img/userLogo.png';
import { AppsActions } from '../../store/Apps/AppsSlice';
import IntlMessages from '../../util/IntlMessages';

const UserProfile = () => {
  const { name } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onLogOutHandler = () => {
    dispatch(AuthActions.signOut());
    dispatch(AppsActions.removeSelectedApp());
    localStorage.removeItem('allApps');
    localStorage.removeItem('selectedApp');
  };
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => onLogOutHandler()}>
        <IntlMessages id="sidebar.user.profile.logout" />
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={userLogo} className="gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className="gx-avatar-name">
          {name}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
