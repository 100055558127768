import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { authAction } from './authActions';

const getPremissionFromToken = (token) => {
  if (token) {
    const decoded = jwtDecode(token);
    return JSON.parse(decoded.permission).PermissionIds;
  }
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    loader: false,
    alertMessage: '',
    showMessage: false,
    authUser: localStorage.getItem('authData') && JSON.parse(localStorage.getItem('authData')).token,
    name: localStorage.getItem('authData') && JSON.parse(localStorage.getItem('authData')).name,
    permissionIds: localStorage.getItem('authData') && getPremissionFromToken(JSON.parse(localStorage.getItem('authData')).token),
  },
  reducers: {
    hideMessage(state) {
      state.alertMessage = '';
      state.showMessage = false;
      state.loader = false;
    },
    signOut(state) {
      localStorage.removeItem('authData');
      state.authUser = null;
      state.loader = false;
    },
  },
  extraReducers: {
    [authAction.pending]: (state) => {
      state.loader = true;
    },
    [authAction.fulfilled]: (state, action) => {
      state.loader = false;
      state.authUser = action.payload.token;
      state.name = action.payload.decodedJwt.name;

      // get permision ids
      const permission = JSON.parse(action.payload.decodedJwt.permission);
      state.permissionIds = permission.PermissionIds;
    },
    [authAction.rejected]: (state) => {
      state.loader = false;
      state.alertMessage = 'Login or password incorrect';
      state.showMessage = true;
    },
  },
});

export const AuthActions = AuthSlice.actions;
export default AuthSlice;
