import { configureStore } from '@reduxjs/toolkit';
import AppsSlice from './Apps/AppsSlice';
import AuthSlice from './Auth/AuthSlice';
import BrandingSlice from './Branding/BrandingSlice';
import CommonSlice from './Common/commonSlice';
import DashboardSlice from './Dashboard/DashboardSlice';
import PhotosApprovalSlice from './PhotosApproval/PhotosApprovalSlice';
import SettingsSlice from './Settings/SettingsSlice';

const store = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    settings: SettingsSlice.reducer,
    common: CommonSlice.reducer,
    apps: AppsSlice.reducer,
    dashboard: DashboardSlice.reducer,
    photosApproval: PhotosApprovalSlice.reducer,
    branding: BrandingSlice.reducer,
  },
});

export default store;
