import { createSlice } from '@reduxjs/toolkit';

const CommonSlice = createSlice({
  name: 'common',
  initialState: {
    error: '',
    loading: false,
    message: '',
    pathname: '/',
  },
  reducers: {
    fetchStart(state) {
      state.error = '';
      state.message = '';
      state.loading = true;
    },
    fetchSuccess(state) {
      state.error = '';
      state.message = '';
      state.loading = false;
    },
    fetchError(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = '';
    },
    showMessage(state, action) {
      state.error = '';
      state.message = action.payload;
      state.loading = false;
    },
    hideMessage(state) {
      state.loading = false;
      state.error = '';
      state.message = '';
    },
  },
});

export const CommonActions = CommonSlice.actions;
export default CommonSlice;
