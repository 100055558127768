import { request } from './Request';

const API_URL = process.env.REACT_APP_API_URL;

const getTheme = (id) => {
  return request.get(`${API_URL}/api/content/application/app/${id}/getTheme`);
};

const setTheme = (id, product) => {
  return request.post(`${API_URL}/api/content/application/app/${id}/setTheme`, product);
};

export const settingsService = {
  getTheme,
  setTheme,
};
